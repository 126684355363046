<script setup lang="ts">
</script>

<script lang="ts" setup>
const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('sUKidGrWZnSFvQcc-oubC')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('KqygIFeBrzgqPjxfARewX')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})
</script>

<template>
  <article class="feq-root">
    <div class="feq-content page-content-width">
      <h1>{{ $t('sUKidGrWZnSFvQcc-oubC') }}</h1>
      <p>{{ $t('KqygIFeBrzgqPjxfARewX') }}</p>
      <p>{{ $t('x9RbTTvQN5Tnq4Tld_otF') }}</p>
      <p>{{ $t('SWMoiz1kOn54tg_nc5ejN') }}</p>
      <p>{{ $t('UejkiguJUidzwJgun0jKx') }}</p>
      <p>{{ $t('eqAcAlLGTqFmRrtbd23VM') }}</p>

      <img src="@/assets/imgs/help/faq3answer1.png" alt="">
      <p>{{ $t('9ungGFEJxNv258BTWlMFU') }}</p>
      <p>
        {{ $t('OXCuc4eo6rLDxAK_PjxUl') }} <NuxtLinkLocale to="/help/q4" data-test-id="Xcv5">{{ $t('DvBNjZBnZYL7HWggpOQLR') }}</NuxtLinkLocale>
      </p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
